<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          <div class="row">
            <div class="col-3">
              <CHeaderNav>
                <CHeaderNavItem class="px-3">
                  <button
                      class="c-header-nav-btn"
                      @click="openSidebarEvent('packingFilter')"
                  >
                    <CIcon size="lg" name="cil-filter" class="mr-2"/>
                  </button>
                </CHeaderNavItem>
              </CHeaderNav>
            </div>
            <div class="col-9 text-right">
              <CButton color="success" square size="sm" @click="openModalEvent('create', 'packingForm', null, 'Yeni Ekle')">Yeni Ekle</CButton>
            </div>
          </div>
        </CCardHeader>

        <CCardBody>
          <CDataTable
              :items="productionPackings"
              :fields="fields"
              :items-per-page="10"
              :active-page="1"
              :loading="loading"
              pagination
              hover
              clickable-rows
              @row-clicked="rowClicked"
              @row-double-clicked="openModalEvent('update', 'packingForm', lastItem, 'Düzenle', null, 
                [{name: 'Tüm Kolileri Sil', color: 'danger', action: 'allDelete'}, {name: 'Koli Ekle', color: 'warning', action: 'additional'}])"
              v-on:refresh="filterProductionPackings"
          >
            <template #inspectionFlag="{item}">
              <td>
                <CIcon v-if="item.inspectionFlag" class="green"
                       name="cil-check-alt"/>
                <CIcon v-else name="cil-x" class="red"/>
              </td>
            </template>
            <template #createTime="{item}"> 
              <td> {{ dateFormat(item.createTime) }}</td>
            </template>
            <template #show_details="{item}">
              <td class="py-2">
                <CDropdown
                    toggler-text="İşlemler"
                    class="m-2"
                    color="info"
                    size="sm"
                >
                  <CDropdownItem 
                    @click="openModalEvent('update', 'packingForm', item, 'Düzenle - '+item.productName+' - '+item.gender, null, 
                    [{name: 'Tüm Kolileri Sil', color: 'danger', action: 'allDelete'}, {name: 'Koli Ekle', color: 'warning', action: 'additional'}])"
                  >
                    Detay
                  </CDropdownItem>
                  <CDropdownItem 
                    @click="openModalEvent('inspection', 'confirm', item, 'Denetimi Yap', item.id+' idli kaydın denetimini yaptığınızı onaylıyor musunuz?')">
                    {{item.inspectionFlag ? 'Denetim Onayını Kaldır' : 'Denetim Yapıldı'}}
                  </CDropdownItem>
                  <CDropdownItem v-if="loggedUser.role.id === 1 || authIds.includes(loggedUser.id)" @click="openModalEvent('destroy', 'confirm', item, 'Sil', item.id+' idli kaydı silmek istediğinize emin misiniz?')">Sil</CDropdownItem>
                  <CDropdownItem @click="getexcelbypackinglist(item.id)">Excel'e Aktar</CDropdownItem>
                  <CDropdownItem :disabled="!item.inspectionFlag" @click="openModalEvent('print', 'confirm', item, 'Çeki Listesini Yazdır', item.id+' idli çeki listesini yazdırmak istediğinize emin misiniz?')">
                    Yazdır
                  </CDropdownItem>
                </CDropdown>
              </td>
            </template>
          </CDataTable>
        </CCardBody>
      </CCard>
    </CCol>
    <ConfirmModal
      v-if="form == 'confirm'" 
      :show="openModal" 
      :title="modalTitle" 
      :openModalEvent="openModalEvent"
      :confirm="removeItem"
      :desc="modalDesc"
      :data="modalProps"
      :actionType="actionType"
      :loading="loading"
      ref="confirm"
      :size="confirmSize"
    />
    <FormModal 
      v-else
      :show="openModal" 
      :title="modalTitle" 
      :openModalEvent="openModalEvent"
      :operationEvent="packingForm"
      :actionType="actionType"
      :module="module"
      :form="form"
      :data="modalProps"
      :additionalButtons="additionalButtons"
      :additionalEvent="additionalEvent"
      :loading="loading"

    />
    <FilterSidebar 
      :show="openSidebar" 
      :openSidebarEvent="openSidebarEvent"
      :operationEvent="filterProductionPackings"
      :module="module"
      :form="filterForm"
      :data="filterParams"
      :loading="loading"
    />
  </CRow>
</template>

<script>
import moment from 'moment';
import FilterSidebar from '../components/filterSidebar'
import FormModal from '../components/formModal'
import ConfirmModal from '../components/confirmModal'
import { prepareFilterParams } from "@/helpers/custom"

export default {
  name: 'ProductionPacking',
  components:{ FilterSidebar, FormModal, ConfirmModal },
  data() {
    return {
      authIds: [42, 77], // 42 Aslının Id si; 77 sinanın Idsi
      confirmSize: 'sm',
      modalTitle: '',
      actionType: '',
      module: 'production',
      form: 'packingForm',
      modalProps: {},
      modalDesc: '',
      additionalButtons: [],
      openModal: false,
      openSidebar: false,
      filterForm: 'packingFilter',
      filterParams: {},
      lastItem: {},
      additional: false,
      activePage: 1,
      fields: [
        {
          key: 'show_details',
          label: 'İşlemler',
          _style: 'font-size:12px'
        },
        {key: 'id', label: "ID", _style: 'font-size:12px'},
        {key: 'createTime', label: 'Oluş. Tarihi', _style: 'font-size:12px'},
        {key: 'productionOrderId', label: 'Sipariş ID', _style: 'font-size:12px'},
        {key: 'productionOrder', label: 'Sipariş No', _style: 'font-size:12px'},
        {key: 'shipmentNumber', label: 'Sevkiyat No', _style: 'font-size:12px'},
        {key: 'productName', label: 'Model', _style: 'font-size:12px'},
        {key: 'productTypeName', label: 'Ürün Tipi', _style: 'font-size:12px'},
        {key: 'gender', label: 'Cinsiyet', _style: 'font-size:12px'},
        {key: 'producerCode', label: 'Üretici Kodu', _style: 'font-size:12px'},
        {key: 'totalAmount', label: 'Girilen Adet', _style: 'font-size:12px'},
        {key: 'finalTotal', label: 'Genel Toplam', _style: 'font-size:12px'},
        {key: 'inspectionFlag', label: 'Denetim', _style: 'font-size:12px'}
      ],
     
      filters: [{
        type: 'date',
        dataIndex: 'startTime',
        field: 'createTime',
        comparison: 'gt',
      }, {
        type: 'string',
        dataIndex: 'productionOrder',
        field: 'productionOrder'
      }, {
        type: 'string',
        dataIndex: 'productionOrderId',
        field: 'productionOrderId'
      }, {
        type: 'string',
        dataIndex: 'shipmentNumber',
        field: 'shipmentNumber'
      }, {
        type: 'string',
        dataIndex: 'productName',
        field: 'productName'
      }, {
        type: 'string',
        dataIndex: 'productTypeName',
        field: 'productTypeName',
      }, {
        type: 'string',
        dataIndex: 'producerCode',
        field: 'producerCode'
      }, {
        type: 'boolean',
        dataIndex: 'inspectionFlag',
        field: 'inspectionFlag'
      },
        {
          type: 'date',
          dataIndex: 'endTime',
          field: 'endTime',
          comparison: 'lt',
        }],
      loggedUser: JSON.parse(localStorage.getItem('user')).loggedUser
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page)
        }
      }
    },
  },
  computed: {
    reloadParams() {
      return [
        this.activePage
      ]
    },
    productionPackings(){
      return this.$store.getters.productionPackings
    },
    loading() {
      return this.$store.getters.productionLoading
    },
    productionPackingPrint() {
      return this.$store.getters.productionPackingPrint
    },
  },
  methods: {
    rowClicked(item) {
      this.lastItem = item
    },
    async openModalEvent(actionType, form, data, title, desc, buttons){
      this.confirmSize = 'sm'
      if (form == 'packingForm' && actionType == 'update') {
        await this.$store.dispatch('productionPackingItem_generate', { packingId: this.lastItem.id})
        if(this.loggedUser.role.id !== 1 && !this.authIds.includes(this.loggedUser.id) && buttons ){
          let bId = buttons.findIndex(b => b.action == 'allDelete')
          bId > -1 && buttons.splice(bId, 1)
        }
        if(actionType == 'update'){
          await this.productionPackingList(data.id);
          await this.$store.dispatch('productionCompanyOrder_list', {productionCompanyId: data.productionCompany})
          let orderNo = this.$store.getters.productionCompanyOrders.find(o => o.orderNo == data.productionOrder).id
          data.orderNo = orderNo
          await this.$store.dispatch('productionModel_list', { productionOrderId: orderNo,gender: data.gender })
          data.modelNo = data.productId
        }else{
          this.$store.state.Production.productionModels = []
        }
      }else if(form == 'packingItemForm'){
        await this.$store.dispatch('productionPackingItem_generate', { packingId: this.lastItem.id})
        if(!data.packingNumber && this.$store.getters.productionPackingItems.length > 0){
          data.packingNumber = this.$store.getters.productionPackingItems[this.$store.getters.productionPackingItems.length - 1].packingNumber + 1
        }else{
          data.packingNumber = data.packingNumber ? data.packingNumber : 1
        }
     
      }else if(actionType == 'print'){
        this.printProductionPackingList(data.id)
        this.confirmSize = 'xl'
      }
      if(this.additional){
        this.additional = false
        this.openModalEvent('update', 'packingForm', this.lastItem, 'Düzenle', null, 
        [{name: 'Tüm Kolileri Sil', color: 'danger', action: 'allDelete'}, {name: 'Koli Ekle', color: 'warning', action: 'additional'}])
      }
      this.openModal = !this.openModal
      this.actionType = actionType
      this.form = form
      this.modalProps = data
      this.modalTitle = title
      this.modalDesc = desc,
      this.additionalButtons = buttons
    },
    async additionalEvent(data, action){
      await this.openModalEvent();
      if(action == 'destroy'){
       await this.openModalEvent(action, 'confirm', data, 'Koli Sil', data.packingNumber+' numaralı koliyi silmek istediğinize emin misiniz?');
      }else if(action == 'allDelete'){
       await this.openModalEvent(action , 'confirm', data, 'Tüm Kolileri Sil', data.id+' idli sipariş çekine ait tüm kolileri silmek istediğine emin misin ?');
      }else{
        
        this.$store.getters.productionGenerateItem.map( (item, i) => {
          data['sku'+(i+1)] = data[item.name]
        })

        await this.openModalEvent(action == 'additional' ? 'create' : action, 'packingItemForm', data, 'Koli İşlemleri');
      }
      this.additional = true
    },
    async removeItem(data, action){
      if(action == 'inspection'){
        let params = { ids: data.id }
        await this.$store.dispatch('productionPacking_inspection', params)
      }else if(action == 'print'){
        var divContents = this.$refs.confirm.$refs.desc.innerHTML;
        var a = window.open('', '', 'height=500, width=500');
        await a.document.write('<style rel="stylesheet" type="text/css">'+
        '.row{width: 98% !important; margin: 0 1% !important;}'+
        '.col{padding: 0 !important; margin: 0 !important;}'+
        '.col-left{width: 55% !important; margin: 0 1%;}'+
        '.col-right{ margin: 0 1%;}'+
        '.barcodeImg{ width: 100%}'+
        '.production-packing-list:nth-child(2n){ page-break-after: always;}'+
        '</style>');
        await a.document.write(divContents);
        //a.document.close();
        setTimeout(() => a.print(), 100)
        
      }else if(action == 'allDelete'){
        let params = { productionPackingId: data.id, authId: this.authIds.includes(this.loggedUser.id) ? this.loggedUser.id : 42 }
        await this.$store.dispatch('productionPackingItem_allDestroy', params)
      }else if(data.packingNumber){
        let params = { packingListId: data.id }
        await this.$store.dispatch('productionPackingItem_destroy', params)
      }else{
        let params = { packingId: data.id, authId: this.authIds.includes(this.loggedUser.id) ? this.loggedUser.id : 42 } // 42 Aslının ID si 
        await this.$store.dispatch('productionPacking_destroy', params)
      }
      if(this.$store.getters.productionStatus.success) {
          this.filterProductionPackings(this.filterParams);
          this.openModalEvent()
        }
    },
    packingForm: async function(data, action){
      if(this.form == 'packingForm'){
        let params = {
          actionType: action,
          orderNo:  data.orderNo,//this.$store.getters.productionCompanyOrders.find(o => o.id == data.orderNo).orderNo,
          shipmentNo: data.shipmentNumber,
          modelNo:  data.modelNo,
          producerCode: data.producerCode,
          gender:  data.gender,
          finalTotal: data.finalTotal,
          productionPackingId: data.id,
        };
        await this.$store.dispatch('productionPacking_action', params);
        if(this.$store.getters.productionStatus.success) {
          this.filterProductionPackings(this.filterParams);
          this.openModalEvent()
        }
      }else{
        let params = {
          "packingId": this.lastItem.id,
          "packingListId":   action == 'update' ? data.id: '' , 
          "packingNumber": data.packingNumber,
          "shipmentLocation": data.shipmentLocation,
          "addOrUpdate": action == 'update' ? 'update' : 'add'
        };
        this.$store.getters.productionGenerateItem.map((item, i) => {
          params['skuId'+(i+1)] = item.id;
          params['sku'+(i+1)] = data['sku'+(i+1)] ? data['sku'+(i+1)] : 0;
        })
        await this.$store.dispatch('productionPackingItem_action', params);
        if(this.$store.getters.productionStatus.success) {
          this.filterProductionPackings(this.filterParams);
          this.openModalEvent('update', 'packingForm', this.lastItem, 'Düzenle', null, 
          [{name: 'Tüm Kolileri Sil', color: 'danger', action: 'allDelete'}, {name: 'Koli Ekle', color: 'warning', action: 'additional'}])
        }
      }
    },
    dateFormat(data){
      return moment(data).format('DD.MM.YYYY hh:mm:ss')
    },
    openSidebarEvent( form){
      this.openSidebar = !this.openSidebar
      this.filterForm = form
    },

    async productionPacking() {
      await this.$store.dispatch('productionPacking_list')
    },
    async productionPackingList(packingId) {
      await this.$store.dispatch('productionPackingItem_list', {packingId: packingId})
    },
    async productionCompanies() {
      await this.$store.dispatch('productionCompanies_list')

    },
    async getexcelbypackinglist(packingId) {
      const params = {
        "packingId": packingId,
        responseType: 'blob'
      };
      await this.$store.dispatch('productionPacking_excel', params)
      const url = window.URL.createObjectURL(new Blob([this.$store.getters.productionStatus]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Sipariş_Ceki_Listesi.xls`);
      document.body.appendChild(link);
      link.click();
    },
    async printProductionPackingList(packingId) {
      let params = {
        "packingId": packingId
      };
      await this.$store.dispatch('productionPacking_print', params)
      this.modalDesc = this.$store.getters.productionPackingPrint
    },
    async filterProductionPackings(params) {
      this.filterParams = params
      let formData = prepareFilterParams(params, this.filters)

      await this.$store.dispatch('productionPacking_list', formData)
      
      if(this.openSidebar){
        await this.openSidebarEvent()
      }
    }
  },
  created() {
    this.productionPacking();
    this.productionCompanies();
  }
}
</script>
<style>
input,
select {
  padding: 0.45em 0.5em;
  font-size: 100%;
  border: 1px solid #ccc;
  width: 100%;
}
.col.sizes{
  display: flex;
}
.col-right{
  display: flex;
}
.col-left{
  display: flex;
}
.col-title{
  display: flex;
  align-items: center;
}
.barcodeImg{
  width: 100%
}
.total-main{
  margin-left: 5px;
}
</style>
